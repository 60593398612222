.video {
    width: 100%;
    height: 529px;
    border-radius: 2.5rem;

    @include media1600 {
        height: 440px;
    }

    @include media1366 {
        height: 372px;
    }

    @include media1363 {
        height: 372px;
    }

    @include mediaLg {
        height: 275px;
    }

    @include mediaXSm-large {
        height: 207px;
    }

    @include media-iphone-se {
        height: 199px;
    }
}

video::-internal-media-controls-download-button {
    display:none;
}
    
video::-webkit-media-controls-enclosure {
    overflow:hidden;
}
    
video::-webkit-media-controls-panel {
    width: calc(100% + 30px); /* Adjust as needed */
}
    
audio::-internal-media-controls-download-button {
    display:none !important;
}
    
audio::-webkit-media-controls-enclosure {
    overflow:hidden !important;
}
    
audio::-webkit-media-controls-panel {
    width: calc(100% + 30px) !important;
}
