.content-img-bg {
    width: 100%;
    text-align: center;
    position: relative;

    img {
        width: 100%;
    }

    .overlay {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background: rgba(0, 0, 0, .63);
        position: absolute;
    }

    .content-text {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        display: flex;
        position: absolute;
        align-items: center;

        @include media1600 {
            > .container-fluid {
                > .row.justify-content-center.flex-column.align-items-center.mt-xl-5.pt-xl-5 {
                    margin-top: 0 !important;

                    .col-xl-5 {
                        .text-init {
                            font-size: 2rem;
                        }
                    }
                }
            }
        }
    
        @include media1366 {
            > .container-fluid {
                > .row.justify-content-center.flex-column.align-items-center.mt-xl-5.pt-xl-5 {
                    margin-top: 0 !important;
                    padding-top: 0 !important;

                    .col-xl-5 {
                        flex: 0 0 auto;
                        width: 47.666667%;

                        .text-init {
                            font-size: 2rem;
                        }
                    }
                }
            }
        }
    
        @include media1363 {
            > .container-fluid {
                > .row.justify-content-center.flex-column.align-items-center.mt-xl-5.pt-xl-5 {
                    margin-top: 0 !important;
                    padding-top: 0 !important;

                    .col-xl-5 {
                        flex: 0 0 auto;
                        width: 47.666667%;

                        .text-init {
                            font-size: 2rem;
                        }
                    }
                }
            }
        }
        
        @include media-iphone-se {
            > .container-fluid {
                > .row.justify-content-center.flex-column.align-items-center.mt-xl-5.pt-xl-5 {
                    margin-top: 0 !important;
                    padding-top: 0 !important;

                    .col-xl-5 {
                        flex: 0 0 auto;
                        width: 90.666667%;

                        .text-init {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }

    .img-init {
        @include media1024 {
            width: 70%;
        }

        @include mediaXSm-large {
            width: 40%;
        }
        
        @include mediaXSm {
            width: 40%;
        }
    }

    .text-init {
        font-size: 3rem;
        color: #ffffff;
        font-weight: 600;

        @include media1024 {
            font-size: 2rem !important;
        }

        @include mediaXSm-large {
            font-size: 1rem !important;
        }
        
        @include mediaXSm {
            font-size: 1rem !important;
        }
    }
}
