.about-us-title {
    font-size: 4rem;

    @include media1600 {
        font-size: 3rem;
    }

    @include media1366 {
        font-size: 3rem;
    }

    @include media1363 {
        font-size: 3rem;
    }

    @include mediaXSm-large {
        font-size: 2rem;
    }

    @include media-iphone-se {
        font-size: 2.5rem;
    }
}

.wrapper-img-about-us {
    width: 100%;
    height: 72rem;
    display: flex;
    align-items: end;
    background-size: 102.5%;
    justify-content: center;
    background-repeat: no-repeat;
    background-origin: border-box;
    background-position: top center;

    @include media1600 {
        height: 60rem;

        .col-md-6 {
            flex: 0 0 auto;
            width: 64%;
        }
    }

    @include media1366 {
        height: 50rem;

        .col-md-6 {
            flex: 0 0 auto;
            width: 64%;
        }
    }

    @include media1363 {
        height: 50rem;

        .col-md-6 {
            flex: 0 0 auto;
            width: 64%;
        }
    }
    
    @include mediaLg {
        height: 38rem;

        .col-md-6 {
            flex: 0 0 auto;
            width: 83%;
            margin-bottom: 0 !important;
        }
    }

    @include mediaXSm-large {
        height: 24rem;
    }
    
    @include mediaXSm {
        height: 24rem;
    }

    @include media-iphone-se {
        height: 24rem;
    }

    .card {
        border: none;
    }
}
