.contact-content {
    @include mediaLg {
        .col-md-5 {
            flex: 0 0 auto;
            width: 46.666667%;
        }
    }
    
    @include media-iphone-se {
        .col-md-5 {
            flex: 0 0 auto;
            width: 100%;
        }
    }
}
