.item {
    position: relative;

    &.item-hover {
        width: 249px;
        height: 296px;
        display: flex;
        cursor: pointer;
        overflow: hidden;
        text-align: center;
        border-radius: 4rem;
        align-items: center;
        vertical-align: middle;
        justify-content: center;

        img {
            display: block;
            width: 190%;
        }

        .content-item-hover {
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            padding: 15px;
            color: white;
            font-weight: 600;
            position: absolute;
            text-align: center;
            background: #003B18;
            border-radius: 1.5rem;
            transition: all 0.2s ease-in-out;

            h3 {
                font-weight: 600;
            }
        }

        &:hover {
            .content-item-hover {
                opacity: 1;
            }
        }
    }

    &.item-hover-2 {
        cursor: pointer;
        text-align: center;

        .content-item-hover {
            opacity: 0;
            padding: 1rem;
            color: white;
            font-weight: 600;
            margin-top: 2rem;
            text-align: center;
            background: #003b18;
            border-radius: 1.5rem;
            transition: all 0.2s ease-in-out;
            
            h3 {
                font-weight: 600;
                margin: 0 !important;
            }
        }
    }

    &.item-hover-3 {
        cursor: pointer;

        .content-item-hover {
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            padding: 15px;
            color: white;
            font-weight: 600;
            position: absolute;
            text-align: center;
            background: #003B18;
            border-radius: 1.5rem;
            transition: all 0.2s ease-in-out;

            h3 {
                font-weight: 600;
            }
        }

        &:hover {
            .content-item-hover {
                opacity: 1;
            }
        }
    }

    img.item-img-description {
        left: 5%;
        top: 40.5%;
        z-index: 1;
        width: 90%;
        position: absolute;

        &.confianza {
            top: 35.5%;
            left: 5.5%;
        }
        
        &.pasion {
            top: 41.5%;
        }
    }
}

.owl-item.active.center {
    .item {
        &.item-hover-2 {
            .content-item-hover {
                opacity: 1;
            }
        }
    }
}

.owl-item.active:not(.center) {
    .item {
        &.item-hover-2 {
            transform: scale(0.7);
            filter: blur(5px);
        }
    }
}
