.festo-img-bg {
    width: 100%;
    text-align: center;
    position: relative;

    img {
        width: 100%;
    }

    .content-text {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        display: flex;
        position: absolute;
        align-items: center;
    }

    @include media1600 {
        .row.justify-content-center.flex-column.align-items-center.my-xl-5.py-xl-5 {
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
        }
    }

    @include media1366 {
        .row.justify-content-center.flex-column.align-items-center.my-xl-5.py-xl-5 {
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
        }

        .row.justify-content-end {
            > .col-12.col-md-9.mt-4.mt-md-5 {
                margin-top: 0 !important;
            }
        }
    }

    @include media1363 {
        .row.justify-content-center.flex-column.align-items-center.my-xl-5.py-xl-5 {
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
        }

        .row.justify-content-end {
            > .col-12.col-md-9.mt-4.mt-md-5 {
                margin-top: 0 !important;
            }
        }
    }
    
    @include mediaLg {
        .row.justify-content-end {
            > .col-12.col-md-9.mt-4.mt-md-5 {
                .col-md-6 {
                    flex: 0 0 auto;
                    width: 57%;
                }
            }
        }
    }

    @include mediaXSm {
        .row.justify-content-center.flex-column.align-items-center.my-xl-5.py-xl-5 {
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
        }

        .row.justify-content-end {
            > .col-12.col-md-9.mt-4.mt-md-5 {
                margin-top: 0 !important;
            }
        }
    }
    
    @include media-iphone-se {
        .row.justify-content-end {
            > .col-12.col-md-9.mt-4.mt-md-5 {
                .col-md-6 {
                    flex: 0 0 auto;
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

    .img-init {
        @include media1024 {
            width: 70%;
        }

        @include mediaXSm-large {
            width: 40%;
        }
        
        @include mediaXSm {
            width: 60%;
        }
    }
}

.festo-img-bg-2 {
    width: 100%;
    height: 70rem;
    display: flex;
    background-size: 104.5%;
    justify-content: center;
    background-repeat: no-repeat;
    background-origin: border-box;
    background-position: top center;

    @include media1600 {
        height: 50rem;

        .mt-md-5 {
            margin-top: 0rem !important;
        }

        .col-12.col-md-7 {
            flex: 0 0 auto;
            width: 77.333333%;
        }
    }

    @include media1366 {
        height: 50rem;

        .mt-md-5 {
            margin-top: 0rem !important;
        }

        .col-12.col-md-7 {
            flex: 0 0 auto;
            width: 77.333333%;
        }
    }

    @include media1363 {
        height: 50rem;

        .mt-md-5 {
            margin-top: 0rem !important;
        }

        .col-12.col-md-7 {
            flex: 0 0 auto;
            width: 77.333333%;
        }
    }
    
    @include mediaLg {
        height: 39rem;
    }

    @include mediaXSm-large {
        height: 58rem;
        background-size: 450.5%;
    }
    
    @include mediaXSm {
        height: 58rem;
        background-size: 450.5%;
    }

    @include media-iphone-se {
        height: 50rem;
        background-size: 404.5%;

        .mt-md-5 {
            margin-top: 0rem !important;
        }

        .col-12.col-md-7 {
            flex: 0 0 auto;
            width: 100%;
        }
    }
}

.bg-section-festo {
    @include media1366 {
        img {
            width: 100%;
        }

        .col-12.col-md-4.content-text {
            flex: 0 0 auto;
            width: 41.333333%;
        }
    }

    @include media1363 {
        img {
            width: 100%;
        }

        .col-12.col-md-4.content-text {
            flex: 0 0 auto;
            width: 41.333333%;
        }
    }
    
    @include mediaLg {
        .col-12.col-md-4.content-text {
            flex: 0 0 auto;
            width: 51.333333%;
        }
    }

    @include mediaXSm-large {
        img {
            width: 100%;
        }
    }

    @include media-iphone-se {
        img {
            width: 100%;
        }

        .col-12.col-md-4.content-text {
            flex: 0 0 auto;
            width: 100%;
        }
    }
}

.section-4-festo {
    @include media1366 {
        .col-md-8 {
            flex: 0 0 auto;
            width: 69.666667%;
        }
    }

    @include media1363 {
        .col-md-8 {
            flex: 0 0 auto;
            width: 69.666667%;
        }
    }
    
    @include mediaLg {
        .col-md-8 {
            flex: 0 0 auto;
            width: 78.666667%;
        }
    }

    @include media-iphone-se {
        .col-md-8 {
            flex: 0 0 auto;
            width: 100%;
            text-align: center !important;
        }
    }
}

.section-5-festo {

    @include media1366 {
        .col-md-6 {
            flex: 0 0 auto;
            width: 57%;
        }
    }

    @include media1363 {
        .col-md-6 {
            flex: 0 0 auto;
            width: 57%;
        }
    }
    
    @include media-iphone-se {
        .col-md-6 {
            flex: 0 0 auto;
            width: 100%;
        }
    }

    .container-img-floating {
        position: relative;
        width: 100%;
        height: 66vh;
    
        [class*="img-"] {
            position: absolute;
        }
    
        .img-1 {
            right: 10%;
            top: 0;
        }
        
        .img-2 {
            top: 30%;
        }
        
        .img-3 {
            bottom: 0;
            right: 0;
        }

        @include media1600 {
            height: 75vh;

            .img-1 {
                top: -13%;
            }
        }

        @include media1366 {
            height: 85vh;

            .img-1 {
                top: -13%;
            }
        }

        @include media1363 {
            height: 66vh;

            .img-1 {
                top: -13%;
            }
        }
        
        @include mediaLg {
            height: 70vh;
        }

        @include mediaXSm-large {
            height: 74vh;
            
            .img-1 {
                top: -4%;
            }

            .img-2 {
                top: 38%;
            }
        }
        
        @include mediaXSm {
            height: 100vh;
            
            .img-1 {
                top: -4%;
            }

            .img-2 {
                top: 38%;
            }
        }
        
        @include media-iphone-se {
            height: 110vh;
            
            .img-1 {
                top: -4%;
            }

            .img-2 {
                top: 38%;
            }
        }
    }
}

.section-11-festo {
    @include media1366 {
        .principal-image-section-festo {
            width: 100%;
        }
    }

    @include media1363 {
        .principal-image-section-festo {
            width: 100%;
        }
    }
    
    @include mediaLg {
        .col-md-6 {
            flex: 0 0 auto;
            width: 48%;
        }

        img {
            width: 100%;
        }
    }

    @include mediaXSm-large {
        img {
            width: 100%;
        }
    }

    @include media-iphone-se {
        .principal-image-section-festo {
            width: 100%;
        }

        .col-md-6 {
            flex: 0 0 auto;
            width: 100%;
        }
    }
}
