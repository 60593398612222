// ===============================
//		Bootstrap Layout scss
// ===============================
@import "~bootstrap/scss/bootstrap";

// ===============================
//			Fonts
// ===============================
@font-face {
    font-family: 'Harabara';
    src: url('../fonts/Harabara.ttf') format('truetype');
}

// ===============================
//			Fontawesome
// ===============================
@import "vendor/font-awesome/scss/font-awesome";

// ===============================
//			Breakpoints
// ===============================
@import "breakpoints";

// ===============================
//			Base
// ===============================
@import "base";

// ===============================
//			Text
// ===============================
@import "text";

// ===============================
//			Carousel
// ===============================
@import "carousel";

// ===============================
//			Media
// ===============================
@import "media";

// ===============================
//			Button
// ===============================
@import "button";

// ===============================
//			Navbar
// ===============================
@import "pages/navabar";

// ===============================
//			Home
// ===============================
@import "pages/home";

// ===============================
//			About
// ===============================
@import "pages/about";

// ===============================
//			Festo
// ===============================
@import "pages/festo";

// ===============================
//			Contact
// ===============================
@import "pages/contact";

// ===============================
//			Whatsapp
// ===============================
.whatsapp-right {
	right: 1%;
    bottom: 5%;
    width: 80px;
    z-index: 600;
    position: fixed;

	@include mediaXSm {
		bottom: 10%;
	}

	ul {
		display: flex;
        list-style: none;
		flex-flow: column wrap;

		li {
			a {
				color: #fff;
				font-size: 28px;

				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
}

