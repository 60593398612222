// ===============================
//	Iphone 4-4s-5-5s devices
// ===============================
@mixin media-iphone-4 {
	@media screen and (max-width: 321px) {
		@content;
	}
}

// ===============================
//	Iphone SE devices
// ===============================
@mixin media-iphone-se {
	@media screen and (max-width: 376px) {
		@content;
	}
}

// ===============================
// 	18:9 - 19:9 Android Devices
// ===============================
@mixin mediaXXSm-large {
	@media screen and (max-width: 390px) and (min-height: 759px) {
		@content;
	}
}

// ===============================
// Extra Small 16:9 Android Devices
// ===============================
@mixin mediaXXSm {
	@media screen and (max-width: 390px) {
		@content;
	}
}

// ===============================
// 18:9 - 19:9 Android Devices
// ===============================
@mixin mediaXSm-large {
	@media screen and (max-width: 510px) and (min-height: 810px) {
		@content;
	}
}

// ===============================
// 		Extra Small Devices
// ===============================
@mixin mediaXSm {
	@media screen and (max-width: 575px) {
		@content;
	}
}

// ===============================
// 			Small Devices
// ===============================
@mixin mediaSm {
	@media screen and (max-width: 767px) {
		@content;
	}
}

// ===============================
// 			IPad Mini
// ===============================
@mixin mediaIPadMini {
	@media screen and (max-width: 768px) {
		@content;
	}
}

// ===============================
// 		Medium Devices Portrait
// ===============================
@mixin mediaMd {
	@media screen and (max-width: 910px) {
		@content;
	}
}

// ===============================
//			Media 1024
// ===============================
@mixin media1024 {
	@media screen and (max-width: 1024px) {
		@content;
	}
}

// ===============================
// 			Large Devices
// ===============================
@mixin mediaLg {
	@media screen and (max-width: 1280px) {
		@content;
	}
}

// ===============================
//			Media 1363
// ===============================
@mixin media1363 {
	@media screen and (max-width: 1363px) {
		@content;
	}
}

// ===============================
//			Media 1366
// ===============================
@mixin media1366 {
	@media screen and (max-width: 1366px) {
		@content;
	}
}

// ===============================
// 		Large Devices Extra
// ===============================
@mixin mediaLg-Extra {
	@media screen and (max-width: 1400px) {
		@content;
	}
}

// ===============================
//		Extra Large Devices
// ===============================
@mixin mediaXL {
	@media screen and (max-width: 1440px) {
		@content;
	}
}

// ===============================
//			Media 1533
// ===============================
@mixin media1533 {
	@media screen and (max-width: 1533px) {
		@content;
	}
}

// ===============================
//			Media 1534
// ===============================
@mixin media1534 {
	@media screen and (max-width: 1534px) {
		@content;
	}
}

// ===============================
//			Media 1600
// ===============================
@mixin media1600 {
	@media screen and (max-width: 1600px) {
		@content;
	}
}

// ===============================
//	Standar over standar size
// ===============================
@mixin mediaXXL {
	@media screen and (min-width: 1920px) {
		@content;
	}
}
