.title-section {
    color: #606060;
    font-weight: 600;
}

.font-title-default {
    font-family: 'Harabara', Helvetica, Arial, Verdana, Tahoma, sans-serif;
    letter-spacing: .07em;
}

.text-grey {
    color: #606060;
}

.text-green {
    color: #003B18;
}

.title-medium {
    font-size: 2.5rem;
}

.title-medium-2 {
    font-size: 3rem;

    @include media-iphone-se {
        font-size: 2.5rem;
    }
}

.big-title {
    font-size: 6rem;

    @include media1600 {
        font-size: 4.5rem;
    }

    @include media1366 {
        font-size: 4.5rem;
    }

    @include media1363 {
        font-size: 5.5rem;
    }
    
    @include mediaLg {
        font-size: 4.5rem;
    }

    @include mediaXSm-large {
        font-size: 3rem;
    }

    @include mediaXSm {
        font-size: 2.5rem;
    }

    @include media-iphone-se {
        font-size: 2.5rem;
    }
}
