.btn-energytech {
    display: flex;
    align-items: center;
    background: #003B18;
    border-radius: 2rem !important;
    
    &:hover,
    &:focus,
    &:active {
        background: #003B18;
    }
}

.btn-energy {
    padding: 1rem 2.5rem;
}

.btn-energytech-white {
    display: flex;
    align-items: center;
    background: white;
    border-radius: 2rem !important;
    
    &:hover,
    &:focus,
    &:active {
        background: white;
    }
}
