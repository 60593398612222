html, body {
    margin: 0;
    padding: 0;
    width: 100vw;
    overflow-x: hidden;
    background: #f6f6f6;
    font-family: 'Raleway', sans-serif;
}

.main-font {
    font-family: 'Raleway', Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.chevron-left,
.chevron-right {
    svg {
        cursor: pointer;
    }
}

.form-search-navbar {
    display: flex;
    align-items: center;
    background: #ffffff;
    border-radius: 3rem;
    border: var(--bs-border-width) solid var(--bs-border-color);

    .input-search {
        border: none;
        border-radius: 3rem;
        box-shadow: none;
    }

    svg {
        margin-right: 1rem;
        stroke: #606060;
    }
}

.custom-tooltip {
    --bs-tooltip-bg: #003b18;
    --bs-tooltip-color: #ffffff;
    font-family: 'Raleway', Helvetica, Arial, Verdana, Tahoma, sans-serif;
    font-size: 13.52px;
    font-weight: 500;
}

.bg-section {
    background: #003B18;
}

.footer {
    padding: 2rem 0;
    background: #003B18;

    #energytech-iso-logo-white {
        width: 10rem;
        height: 10rem;
    }

    .container-social-icons {
        width: calc(100% / 5);
        text-align: center;

        a {
            text-decoration: none;
            color: white;
        }

        svg {
            width: 3rem;
            height: 3rem;

            @include media1600 {
                width: 2rem;
                height: 2rem;
            }

            @include media1366 {
                width: 2rem;
                height: 2rem;
            }

            @include media1363 {
                width: 2rem;
                height: 2rem;
            }

            @include mediaLg {
                width: 1.5rem;
                height: 1.5rem;
            }

            @include mediaXSm-large {
                width: 2rem;
                height: 2rem;
            }
        }

        &.play {
            svg {
                width: 4rem;
            }
        }

        &.youtube {
            svg {
                width: 7rem;

                @include mediaLg {
                    width: 5rem;
                }

                @include mediaXSm-large {
                    width: 5rem;
                }
            }
        }
    }
}

@include media-iphone-se {
    .col-12 {
        flex: 0 0 auto;
        width: 100% !important;
    }
}

@include mediaXSm {
    .col-12 {
        flex: 0 0 auto;
        width: 100% !important;
    }
}

@include mediaXSm-large {
    .col-12 {
        flex: 0 0 auto;
        width: 100% !important;
    }
}

.rouded-content {
    border-radius: 4rem;
}

.rounded-content {
    border-radius: 2.5rem;
}

.content-mark {
    cursor: pointer;
    position: relative;

    .symbol-plus {
        right: 0;
        bottom: 0;
        width: 36px;
        position: absolute;
    }

    &.special {
        .symbol-plus {
            right: -26px;
            bottom: -26px;
        }
    }
}

#ws-icon-svg,
#phone-icon-svg {
    height: 1.50rem;
    margin-right: 10px;
}
