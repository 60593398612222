.navbar-nav {
    .nav-item {
        .nav-link {
            font-size: 18px;
            font-weight: 600;
        }
    }
}

.navbar-brand {
    width: 15%;
    margin-left: 15px;

    @include media1024 {
        width: 30%;
    }

    @include mediaXSm-large {
        width: 40%;
    }
    
    @include media-iphone-se {
        width: 50%;
    }
}
